<template>
  <section class="get-free-packs">
    <div class="get-free-packs__content">
      <h2 class="get-free-packs__title" v-t="'get-free-packs.title'" />
      <a target="_blank" :href="$t(`get-free-packs.link`)" class="get-free-packs__button">
        <span v-t="'get-free-packs.get'" />
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.915 16.308c6.284-2.765 14.416-6.135 15.538-6.602 2.945-1.22 3.848-.987 3.398 1.717-.324 1.944-1.257 8.378-2.001 12.383-.442 2.375-1.432 2.657-2.99 1.63-.749-.495-4.53-2.995-5.35-3.582-.749-.535-1.782-1.179-.486-2.446.46-.452 3.483-3.337 5.837-5.583.309-.295-.079-.78-.435-.543a2748.796 2748.796 0 00-8.133 5.406c-.846.574-1.659.838-3.117.419-1.102-.317-2.179-.694-2.598-.838-1.613-.554-1.23-1.272.337-1.961z" fill="#636367"/></svg>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: "GetFreePacks"
}
</script>

<style lang="scss">
@import '@/assets/scss/elements/get-free-packs';
</style>